import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { postApiRequest } from "../../../../apiHandler";
import { useState } from "react";

export default function InformationDeLannonce({
  formData,
  setFormData,
  setRef,
}) {
  const [loading, setLoading] = useState(false);
  const formatPriceWithSpaces = (value) => {
    const numericValue = value?.replace(/[^\d]/g, "");
    return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const sanitizedValue = value.replace(/[^\d]/g, "");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: sanitizedValue,
      original_price: prevFormData.price,
    }));
  };

  const excludedTypes = ["appartement", "immeuble", "bureau", "studio"];

  const generateDescriptionWithAI = async () => {
    setLoading(true);
    try {
      const generateDescriptionRes = await postApiRequest(
        "/api/ads/generate-description",
        formData
      );
      const { message, status } = generateDescriptionRes;
      if (status === 200) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: message,
        }));
      }
    } catch (error) {
      console.error("Error fetching description from API:", error);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Typography
        variant="body1"
        fontSize={32}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Information de l’annonce
      </Typography>
      <Typography variant="body1" fontSize={16} color={"#263238"} gutterBottom>
        Une annonce avec un prix exacte et une description bien détaillé a 10
        fois de visibilité.
      </Typography>
      <Grid
        component="form"
        autoComplete="off"
        sx={{
          mt: 4,
          "& .MuiOutlinedInput-root": {
            borderRadius: "22.5px",
            backgroundColor: "#FFFFFF",
            "& fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(132, 159, 186, 0.45)",
            },
          },
        }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          xs={!excludedTypes.includes(formData["property_type"]) ? 12 : 6}
        >
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Prix
              {formData.ads_type === "sale"
                ? " de vente"
                : formData.ads_type === "rent" && " de location"}
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("price")}
              placeholder="Exemple : 1 000 000"
              id="outlined-start-adornment"
              name="price"
              value={formatPriceWithSpaces(formData?.price)}
              type="text"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">DH</InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        {!excludedTypes.includes(formData["property_type"]) ? (
          ""
        ) : (
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
                Frais de syndic / mois
              </Typography>
              <TextField
                placeholder="Exemple : 200"
                id="outlined-start-adornment"
                name="trustee_fees"
                value={formatPriceWithSpaces(formData?.trustee_fees) || ""}
                onChange={handleInputChange}
                type="text"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">DH / Mois</InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: 45,
                    borderRadius: "22.5px",
                    "& fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&:hover fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(132, 159, 186, 0.45)",
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} mt={1.5}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Titre de l’annonce
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("title")}
              placeholder="Titre de l’annonce"
              id="outlined-start-adornment"
              name="title"
              value={formData.title || ""}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1.5}>
          <FormControl variant="outlined" fullWidth>
            <Typography variant="body1" sx={{ fontSize: "12px", mb: 0.5 }}>
              Description
              <span style={{ color: "#EC544B" }}>*</span>
            </Typography>
            <TextField
              ref={setRef("description")}
              fullWidth
              multiline
              rows={4}
              placeholder="Entrez votre description"
              name="description"
              value={formData.description || ""}
              onChange={handleInputChange}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "& textarea::-webkit-scrollbar": {
                    width: "0.6vh !important",
                  },
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={1.5}>
          <Button
            fullWidth
            variant="outlined"
            disabled={loading}
            onClick={generateDescriptionWithAI}
            sx={{
              minWidth: { xs: "85%", sm: "150px" },
              minHeight: { xs: "85%", sm: "56px" },
              px: { xs: 3, sm: 5 },
              my: 2,
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              color: "#FFFFFF",
              background: "#0066EE",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
              "&:disabled": {
                background: "#d1dce6",
              },
            }}
          >
            {loading ? "Chargement..." : "Générer une description avec l'IA."}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
