import React from "react";

import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { HiOutlineDownload } from "react-icons/hi";
import { AiOutlineReload } from "react-icons/ai";
import { FaTrashCan } from "react-icons/fa6";
import Appar from "../Appartment.svg";
import AlertDialog from "./deletePopup";

export default function GroupView({
  filteredData,
  open,
  setOpen,
  setDeleteId,
  deleteDataApi,
}) {
  const CustomBox = styled(Box)(({ theme }) => ({
    background: "#F1F5FF",
    width: "36px",
    height: "36px",
    color: "#2f54eb",
    display: "flex",
    fontSize: "1.3rem",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    cursor: "pointer",
    "&:hover": {
      width: "36px",
      height: "36px",
      border: "1px solid #2f54eb",
      background: "#2f54eb",
      color: "#F1F5FF",
    },
  }));

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };
  return (
    <>
      <Grid container spacing={4} sx={{ mt: 0.5 }}>
        {filteredData &&
          filteredData?.map((Item) => (
            <Grid item xs={12} sm={6} md={4} key={Item._id}>
              {console.log("Item", Item)}
              <Paper
                elevation={2}
                sx={{
                  py: { xs: 2, sm: 4 },
                  px: { xs: 1.5, sm: 3 },
                  width: "100%",
                  borderRadius: "13px",
                  maxHeight: "254px",
                  minHeight: "254px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">
                    {Item?.formattedDate}
                  </Typography>
                  {/* <CustomBox>
                    <NavLink
                      to={Item?.pdffile}
                      target="_blank"
                      className="icondata"
                    >
                      <HiOutlineDownload />
                    </NavLink>
                  </CustomBox> */}
                  <CustomBox>
                    {Item?.pdffile !== null ? (
                      <NavLink
                        to={Item?.pdffile}
                        target="_blank"
                        className="icondata"
                      >
                        <HiOutlineDownload />
                      </NavLink>
                    ) : (
                      // Render something else when pdffile is null (e.g., a disabled icon)
                      <span className="disabled-icondata">
                        <HiOutlineDownload />
                      </span>
                    )}
                  </CustomBox>
                </Stack>
                <Stack
                  my={3}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="left"
                >
                  <Box component="img" src={Appar} alt="appart" />
                  {Item?.address !== null && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "600",
                        my: 3,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis ",
                      }}
                    >
                      {Item?.address}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  sx={{ my: 2, gap: 1 }}
                  alignItems="center"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                >
                  {Item?.type_bien !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                      }}
                      label={Item.type_bien}
                    />
                  )}
                  {Item?.surface !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                      }}
                      label={Item?.surface + " m²"}
                    />
                  )}
                  {Item?.pieces !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                      }}
                      label={Item?.pieces + " Chambre(s)"}
                    />
                  )}
                </Stack>

                <Typography
                  variant="subtitle1"
                  sx={{ mt: 3, fontWeight: "300" }}
                >
                  Dernière estimation :{" "}
                  <b style={{ color: "#2f54eb" }}> {Item?.predictedPrice} </b>
                </Typography>
              </Paper>
              <Stack
                direction="row"
                spacing={{ xs: 0.1, sm: 2, md: 2 }}
                alignItems="center"
                justifyContent="space-around"
                sx={{ mt: 1.5 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AiOutlineReload style={{ fontSize: "1.2rem" }} />}
                  component={Link}
                  to={`${process.env.REACT_APP_WEB_URL}/estimation-result/${Item._id}`}
                  sx={{ px: { xs: 3, sm: 5 } }}
                >
                  Réestimer
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<FaTrashCan style={{ fontSize: "0.9rem" }} />}
                  component={Link}
                  to="#"
                  sx={{ px: { xs: 3, sm: 5 } }}
                  onClick={() => handleDeleteOpen(Item._id)}
                >
                  {" "}
                  Supprimer
                </Button>
              </Stack>
            </Grid>
          ))}
      </Grid>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        deleteDataApi={deleteDataApi}
      />
    </>
  );
}
