import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { TbGridDots } from "react-icons/tb";
import GroupView from "./GroupView";
import DataTable from "./TableData";
import { deleteRequest, getApiRequest } from "../../apiHandler";
import { MdOutlineFileDownload } from "react-icons/md";
import { LiaTrashAlt } from "react-icons/lia";
import { NavLink } from "react-router-dom";
import { AiOutlineReload } from "react-icons/ai";
import { formatDate, formatPrice } from "../../utils/utils";
import TrierPar from "./TrierPar";
import { toast } from "react-toastify";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .Mui-selected": {
    background: "#F0F5FF !important",
    color: "#0066ee !important",
  },

  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default function MesBiens() {
  const [estimesData, setEstimesData] = useState([]);
  const [estimesDataTable, setEstimesDataTable] = useState([]);
  const [toggleView, setToggleView] = useState(true);
  const [alignment, setAlignment] = useState("left");
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectOption, setSelectOption] = useState();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleDeleteOpen = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const deleteDataApi = async () => {
    if (deleteId) {
      const deleteRes = await deleteRequest(
        `/api/delete-estimation/${deleteId}`
      );
      if (deleteRes?.status === 200) {
        toast.success(deleteRes?.message);
        setOpen(false);
        getEstimesData();
      } else {
        toast.error(deleteRes?.message);
      }
    }
  };

  const getEstimesData = async (updateValue) => {
    const estimesdata = await getApiRequest("/api/get-estimation");
    if (estimesdata?.status === 200) {
      const sortResultCards = [...estimesdata?.response]?.sort((a, b) => {
        switch (updateValue?.sortOption) {
          case "creation_date":
            return new Date(a.creation_date) - new Date(b.creation_date);
          // const monthYearA = `${new Date(
          //   a.creation_date
          // ).getMonth()}-${new Date(a.creation_date).getFullYear()}`;
          // const monthYearB = `${new Date(
          //   b.creation_date
          // ).getMonth()}-${new Date(b.creation_date).getFullYear()}`;

          // // Compare by month and year
          // return new Date(monthYearB) - new Date(monthYearA);
          case "type_bien":
            return a.type_bien.localeCompare(b.type_bien);
          case "predicted_price":
            return a.predicted_price - b.predicted_price;
          default:
            return new Date(b.creation_date) - new Date(a.creation_date);
        }
      });
      setEstimesData(sortResultCards);
    } else {
      toast.error(estimesdata?.message);
    }
  };
  React.useEffect(() => {
    const updatedFilteredData = estimesDataTable?.filter((item) => {
      const lowerCaseAddress = item?.address?.toLowerCase();
      const lowerCaseTypeBien = item?.type_bien?.toLowerCase();
      const lowerCaseSearch = search?.toLowerCase();

      return (
        lowerCaseAddress?.includes(lowerCaseSearch) ||
        lowerCaseTypeBien?.includes(lowerCaseSearch)
      );
    });

    setFilteredData(updatedFilteredData);
  }, [search, estimesDataTable]);

  React.useEffect(() => {
    getEstimesData();
  }, []);

  const estimetionTable = async () => {
    const newData = estimesData?.map((item) => {
      console.log("item", item);
      const {
        creation_date,
        address,
        type_bien,
        surface,
        pieces,
        for_property,
        pdffile,
        _id,
        predicted_price,
      } = item;

      const formattedDate = formatDate(creation_date).dateDMY;
      const predictedPrice = formatPrice(parseInt(predicted_price));
      return {
        _id,
        formattedDate,
        address,
        type_bien,
        surface,
        pieces,
        for_property,
        pdffile,
        predictedPrice,
      };
    });
    setEstimesDataTable(newData);
  };

  useEffect(() => {
    estimetionTable();
  }, [estimesData]);

  const estimesKeys = [
    { field: "formattedDate", headerName: "Date", width: 150 },
    { field: "address", headerName: "Adresse", width: 150 },
    { field: "type_bien", headerName: "Type de bien", width: 150 ,},
    { field: "surface", headerName: "Surface", width: 150 },
    { field: "pieces", headerName: "Chambre(s)", width: 150 },
    { field: "for_property", headerName: "Type annonce", width: 150 },
    { field: "predictedPrice", headerName: "Prix ​​estimé", width: 150 },
    {
      field: "delete",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          sx={{
            gap: "12px",
            "&:hover": {
              backgroundColor: "transparent",
            },
            pl: 0,
            ml: "-10px",
          }}
        >
          <NavLink
            to={`${process.env.REACT_APP_WEB_URL}/estimation-result/${params.row._id}`}
            target="_blank"
          >
            <AiOutlineReload
              size={20}
              style={{ color: "#4D4A4A", cursor: "pointer" }}
            />
          </NavLink>
          {params.row.pdffile !== null ? (
            <NavLink to={params.row.pdffile} target="_blank">
              <MdOutlineFileDownload
                size={20}
                style={{ color: "#0066ee", cursor: "pointer" }}
              />
            </NavLink>
          ) : (
            ""
          )}
          <LiaTrashAlt
            size={20}
            style={{ color: "#FF5757", cursor: "pointer" }}
            onClick={() => handleDeleteOpen(params.row._id)}
          />
        </IconButton>
      ),
    },
  ];
  return (
    <Layout>
      <Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 0.8, sm: 2, md: 2 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Paper
            elevation={0}
            variant="outlined"
            component="form"
            sx={{
              p: "5px 4px",
              width: { xs: "100%", sm: "50%" },
              borderRadius: 2,
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <FiSearch style={{ fontSize: "1.2rem" }} />
            </IconButton>

            <InputBase
              sx={{
                ml: 0.5,
                width: { xs: "auto", sm: "88%" },
                input: {
                  color: "#000",
                  width: "100%",
                  "&::placeholder": {
                    // <----- Add this.
                    color: "#6c757d",
                    opacity: 1,
                  },
                },
              }}
              placeholder="Rechercher par adresse ou type"
              inputProps={{ "aria-label": "Nice" }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Paper>
          <a href={`${process.env.REACT_APP_WEB_URL}/estimation-immobiliere`}>
            <Button
              variant="contained"
              color="primary"
              sx={{ px: 3, width: { xs: "100%", sm: "auto" } }}
            >
              Estimer un nouveau bien
            </Button>
          </a>
        </Stack>

        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 4 },
            width: "100%",
            borderRadius: "13px",
            mt: 3,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{ fontWeight: "600" }}>
              Biens estimés ({estimesDataTable?.length})
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                sx={{ display: { xs: "none", md: "inline-flex" }, gap: 2 }}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2" sx={{ fontWeight: "400" }}>
                  Affichage{" "}
                </Typography>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: "wrap",
                  }}
                >
                  <StyledToggleButtonGroup
                    size="small"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      onClick={() => setToggleView(true)}
                      value="left"
                      aria-label="left aligned"
                    >
                      <HiOutlineMenuAlt2 size={18} />
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => setToggleView(false)}
                      value="center"
                      aria-label="centered"
                    >
                      <TbGridDots size={18} />
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Paper>
              </Box>
              {toggleView ? (
                ""
              ) : (
                <TrierPar
                  selectOption={selectOption}
                  setSelectOption={setSelectOption}
                  filteredData={filteredData}
                  getEstimesData={getEstimesData}
                />
              )}
            </Stack>
          </Stack>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <GroupView />
          </Box>
          <Box sx={{ display: { md: "block" } }}>
            {toggleView ? (
              <DataTable
                filteredData={filteredData}
                keysData={estimesKeys}
                open={open}
                setOpen={setOpen}
                deleteDataApi={deleteDataApi}
              />
            ) : (
              <GroupView
                filteredData={filteredData}
                open={open}
                setOpen={setOpen}
                deleteDataApi={deleteDataApi}
                setDeleteId={setDeleteId}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Layout>
  );
}
