import React, { useState } from "react";
import {
  Chip,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import Appar from "../Appartment.svg";
import { formatDate, formatPrice } from "../../utils/utils";
import { FaCircleCheck } from "react-icons/fa6";

export default function ProjectDeVenteCards({
  estimation,
  estimationId,
  setEstimationId,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 3;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the current cards to display
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const currentCards = estimation.slice(startIndex, endIndex);

  return (
    <div style={{ position: "relative" }}>
      <Grid container spacing={{ xs: 2, sm: 2, md: 4 }}>
        {currentCards.map((item) => {
          const {
            created_date,
            address,
            type_bien,
            surface,
            pieces,
            predicted_price,
            _id,
          } = item;

          return (
            <Grid
              item
              key={_id}
              xs={12}
              sm={6}
              md={6}
              lg={4}
              onClick={() => setEstimationId(_id)}
            >
              <Paper
                elevation={2}
                sx={{
                  py: 4,
                  px: 2.5,
                  width: "100%",
                  cursor: "pointer",
                  borderRadius: "13px",
                  border: estimationId === _id ? "2px solid #20C788" : "",
                  maxHeight: "254px",
                  minHeight: "254px",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle1">
                    {formatDate(created_date).dateDMY}
                  </Typography>
                  {estimationId === _id && (
                    <FaCircleCheck
                      color="#20C788"
                      size={24}
                      sx={{ marginLeft: "auto" }}
                    />
                  )}
                </Stack>
                <Stack
                  my={3}
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="start"
                >
                  <Box component="img" src={Appar} alt="appart" />
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "600",
                      my: 3,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis ",
                    }}
                  >
                    {address}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ my: 2, gap: 1 }}
                  alignItems="center"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                >
                  {type_bien !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                        textTransform: "capitalize",
                      }}
                      label={type_bien}
                    />
                  )}
                  {surface !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                      }}
                      label={surface + " m\u00B2 "}
                    />
                  )}
                  {pieces !== null && (
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: "#F3F3F3",
                        color: "#4D4A4A",
                        fontWeight: "300",
                      }}
                      label={pieces + " Chambre(s)"}
                    />
                  )}
                </Stack>
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 3, fontWeight: "300" }}
                >
                  {""}
                  Dernière estimation :
                  <b style={{ color: "#2f54eb" }}>
                    {" "}
                    {formatPrice(parseInt(predicted_price))}
                  </b>
                </Typography>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      {estimation.length > cardsPerPage && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Pagination
            count={Math.ceil(estimation.length / cardsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </div>
  );
}

// import React, { useState } from "react";
// import {
//   Chip,
//   Box,
//   Grid,
//   Paper,
//   Stack,
//   Typography,
//   Button,
// } from "@mui/material";
// import Appar from "../Appartment.svg";
// import { formatDate, formatPrice } from "../../utils/utils";
// import { FaCircleCheck } from "react-icons/fa6";

// export default function ProjectDeVenteCards({
//   estimation,
//   estimationId,
//   setEstimationId,
// }) {
//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [visibleCards, setVisibleCards] = useState(3);
//   const handleCollapseToggle = () => {
//     setIsCollapsed((prev) => !prev);
//     setVisibleCards((prev) => (isCollapsed ? 3 : estimation?.length));
//   };
//   return (
//     <div style={{ position: "relative" }}>
//       <Grid container spacing={{ xs: 2, sm: 2, md: 4 }}>
//         {estimation &&
//           estimation?.slice(0, visibleCards).map((item) => {
//             const {
//               created_date,
//               address,
//               type_bien,
//               surface,
//               pieces,
//               predicted_price,
//               _id,
//             } = item;

//             return (
//               <Grid
//                 item
//                 key={_id}
//                 xs={12}
//                 sm={6}
//                 md={6}
//                 lg={4}
//                 onClick={() => setEstimationId(_id)}
//               >
//                 <Paper
//                   elevation={2}
//                   sx={{
//                     py: 4,
//                     px: 2.5,
//                     width: "100%",
//                     cursor: "pointer",
//                     borderRadius: "13px",
//                     border: estimationId === _id ? "2px solid #20C788" : "",
//                     maxHeight: "254px",
//                     minHeight: "254px",
//                   }}
//                 >
//                   <Stack
//                     direction="row"
//                     spacing={2}
//                     alignItems="center"
//                     justifyContent="space-between"
//                   >
//                     <Typography variant="subtitle1">
//                       {formatDate(created_date).dateDMY}
//                     </Typography>
//                     {estimationId === _id && (
//                       <FaCircleCheck
//                         color="#20C788"
//                         size={24}
//                         sx={{ marginLeft: "auto" }}
//                       />
//                     )}
//                   </Stack>
//                   <Stack
//                     my={3}
//                     direction="row"
//                     spacing={2}
//                     alignItems="center"
//                     justifyContent="start"
//                   >
//                     <Box component="img" src={Appar} alt="appart" />
//                     <Typography
//                       variant="body2"
//                       sx={{
//                         fontWeight: "600",
//                         my: 3,
//                         whiteSpace: "nowrap",
//                         overflow: "hidden",
//                         textOverflow: "ellipsis ",
//                       }}
//                     >
//                       {address}
//                     </Typography>
//                   </Stack>
//                   <Stack
//                     direction="row"
//                     sx={{ my: 2, gap: 1 }}
//                     alignItems="center"
//                     justifyContent="flex-start"
//                     flexWrap="wrap"
//                   >
//                     {type_bien !== null && (
//                       <Chip
//                         sx={{
//                           borderRadius: "5px",
//                           background: "#F3F3F3",
//                           color: "#4D4A4A",
//                           fontWeight: "300",
//                         }}
//                         label={type_bien}
//                       />
//                     )}
//                     {surface !== null && (
//                       <Chip
//                         sx={{
//                           borderRadius: "5px",
//                           background: "#F3F3F3",
//                           color: "#4D4A4A",
//                           fontWeight: "300",
//                         }}
//                         label={surface + " m\u00B2 "}
//                       />
//                     )}
//                     {pieces !== null && (
//                       <Chip
//                         sx={{
//                           borderRadius: "5px",
//                           background: "#F3F3F3",
//                           color: "#4D4A4A",
//                           fontWeight: "300",
//                         }}
//                         label={pieces + " Chambre(s)"}
//                       />
//                     )}
//                   </Stack>
//                   <Typography
//                     variant="subtitle1"
//                     sx={{ mt: 3, fontWeight: "300" }}
//                   >
//                     {""}
//                     Dernière estimation :
//                     <b style={{ color: "#2f54eb" }}>
//                      {" "} {formatPrice(parseInt(predicted_price))}
//                     </b>
//                   </Typography>
//                 </Paper>
//               </Grid>
//             );
//           })}
//       </Grid>
//       {estimation?.length > 3 && (
//         <Button
//           onClick={() => {
//             handleCollapseToggle();
//           }}
//           style={{
//             textTransform: "capitalize",
//             position: "absolute",
//             bottom: "-46px",
//             left: "50%",
//             transform: "translateX(-50%)",
//             color: "rgb(57 48 48 / 60%)",
//           }}
//         >
//           {isCollapsed ? "- Afficher moins" : "+ Afficher tous les biens"}
//         </Button>
//       )}
//     </div>
//   );
// }
